<template>
  <div style="overflow: hidden">
    <!-- <ul
      class="new-list"
      :class="{ anim: animate }"
      @mouseenter="Stop()"
      @mouseleave="Up()"
    > -->
    <ul class="new-list">
      <li v-for="(item, index) in noticeList" :key="index" @click="go(item)">
        <p class="li" v-if="index<numAdd">{{ item.title }}</p>
        <!--v-if="index<6"-->
      </li>
    </ul>
  </div>
</template>
   
   <script>
export default {
  data() {
    return {
      //   noticeList: [],

      intNum: undefined,
      // numAdd:2
    };
  },
  props: {
    noticeList: Array,
    numAdd:Number
  },
  created: function () {
    // this.getNoticeData();
    console.log(this.noticeList);
  },
  mounted(){

    
  
},
  methods: {
    go(content) {
      if (content.url != "") {
        console.log(content.url)
        let pathInfo = this.$router.resolve({
          path: content.url,
        });
        debugger
        console.log(pathInfo)
        window.open((pathInfo.href).split('#/')[1], "_blank");
      } else {
        this.$router.push({ path: "./infoDetail" });
      }
      console.log(content.content)
      window.localStorage.setItem('anjing_ds_content',content.content);
    },
    getNoticeData() {
      //   this.$http
      //     .get("/news/allList", {
      //       params: {
      //         pageNumber: 10,
      //         currentPage: 1,
      //       },
      //     })
      //     .then((res) => {
      //       this.noticeList = res.data.items;
      //       this.ScrollUp();
      //     });

      this.ScrollUp();
    },
    ScrollUp() {
      this.intNum = setInterval(() => {
        this.animate = true; // 向上滚动的时候需要添加css3过渡动画
        setTimeout(() => {
          this.noticeList.push(this.noticeList[0]); // 将数组的第一个元素添加到数组的
          this.noticeList.shift(); //删除数组的第一个元素
          this.animate = false;
        }, 500);
      }, 2000);
    },
    //鼠标移上去停止
    Stop() {
      clearInterval(this.intNum);
    },
    Up() {
      this.ScrollUp();
    },
  },
};
</script>
   
  <style scoped>
ul,
li {
  list-style: none;
  padding: 0;
}
.new-list {
  line-height: 28px;
  transition: top 0.5s;
  height: 100%;
  overflow: auto;
}
.new-list .li {
  color: #0085b9;
  cursor: pointer;
  /* border-bottom: 1px solid #ccc; */
  margin: 5px 0;
  background: #e2f3fb;
  border-radius: 5px;
  padding: 4px 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.anim {
  transition: all 0.5s;
  margin-top: -28px;
}
</style>