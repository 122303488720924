import { render, staticRenderFns } from "./login-reg-pass.vue?vue&type=template&id=7db44018&scoped=true&"
import script from "./login-reg-pass.vue?vue&type=script&lang=js&"
export * from "./login-reg-pass.vue?vue&type=script&lang=js&"
import style0 from "./login-reg-pass.vue?vue&type=style&index=0&id=7db44018&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7db44018",
  null
  
)

export default component.exports