<template>
  <div>
    <p class="ms">
      还没有账号？<span
        @click="to('./regUrl')"
        style="color: #69a4fa; cursor: pointer"
        >免费注册</span
      >
    </p>
    <div class="reg-com reg-login">
      <login  :pass='false' @loginFun="loginFun(arguments)"></login>
    </div>
  </div>
</template>
<script>
import login from "@/components/login.vue";
export default {
  data() {
    return{
        visible:'',
    }
    


  },
  components: {
    login,
  },
  mounted(){
     window.localStorage.setItem('anjing-joincode',this.$route.query.joincode)
  },
  methods: {
     loginFun(val) {
      this.visible = val[0];
      // 可以登录了
    //   if (val[1] == "ok") {
    //     this.$emit("regFun", this.visible, true);

    //   }
    //   // 前往忘记密码
    //   if (val[2]) {
    //     this.type = val[2];
    //     this.title = '找回密码'
    //     this.currentPass = 0;
    //     console.log(this.type);
    //   }
    },
      to(url) {
      this.$router.push({ path: url ,query:{
        id:'8888'
      }});
    },
  },
};
</script>
<style scoped>
.ms {
  text-align: center;
  margin: 60px 0 50px;
  font-weight: bold;
}
.reg-login {
  padding-top: 0 !important;
}
.reg-com {
  padding: 50px 20px 20px !important;
  width: 412px;
  margin: 0 auto !important;
}
</style>