<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-model-item
      ref="phone"
      label="手机号（国际/港澳台号码格式为：国家区号+手机号码）"
      prop="phone"
      :labelCol="{ span: 24, offset: 0 }"
      labelAlign="left"
    >
      <a-input placeholder="请输入您手机号" v-model="form.phone">
        <a-icon slot="prefix" type="tablet"
      /></a-input>
    </a-form-model-item>
    <a-form-model-item
      class="add"
      ref="code"
      prop="code"
      label="验证码"
      :labelCol="{ span: 24, offset: 0 }"
      labelAlign="left"
      style="width: 67%; display: table-cell"
    >
      <a-input placeholder="请输入您的验证码" v-model="form.code"> </a-input>
    </a-form-model-item>
    <a-form-model-item
      style="width: 25%; display: table-cell; padding-top: 27px"
    >
      <a-button
        type="primary"
        :disabled="codeBtn"
        @click="codeFun"
        style="width: 96px; padding: 0; margin-left: 5px"
      >
        {{ codeText }}
      </a-button>

      <a-drawer
        title="温馨提示"
        placement="top"
        :closable="false"
        :visible="visiblereg"
        :after-visible-change="afterVisibleChange"
        @close="onClose"
        width="400"
      >
        <lrp />
      </a-drawer>
    </a-form-model-item>
    <p class="overflow:hidden;" @click="showDrawer">
      <span class="codeQ">没收到验证码？</span>
    </p>
    <a-form-model-item>
      <a-button type="primary" @click="onSubmitReg" style="width: 100%">
        下一步
      </a-button>
    </a-form-model-item>
    <!-- <a-form-model-item>
       
    </a-form-model-item> -->
  </a-form-model>
</template>
<script>
import lrp from "@/components/lrp.vue";
export default {
  components: {
    lrp,
  },
  data() {
    return {
      visiblereg: false,
      codeBtn: true,
      codeText: "发送验证码",
      countdown: 60,
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
      form: {
        // name: "",
        // pass: "",
        code: "",
        phone: "",
      },
      rules: {
        phone: [
          {
            required: true,
            message: "请输入您的手机号",
            trigger: "blur",
          },
          {
            validator: this.checkGrade,
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入您的验证码",
            trigger: "blur",
          },
          {
            min: 1,
            max: 6,
            message: "请输入1-6个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSubmitReg() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            op: "checkvfycode",
            phone: this.form.phone,
            code: this.form.code,
          };
          let _this = this;
          this.$ajax
            .post(this.url+"/new_registration.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.current = 1;
                _this.$emit("submitFun", 1, 0, 1);
                 window.localStorage.setItem('anjing-phoneNew',_this.form.phone)

              } else {
                _this.$notification.open({
                  message: "绑定手机",
                  duration: 4,
                  description: response.data.msg,
                  onClick: () => {
                    console.log("绑定手机接口");
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          this.current = 0;
          return false;
        }
      });
    },
    showDrawer() {
      this.visiblereg = true;
    },
    onClose() {
      this.visiblereg = false;
    },
    afterVisibleChange(val) {
      console.log("visible", val);
    },
    codeFun() {
      let data = {
        op: "getvfycode",
        phone: this.form.phone,
      };
      let _this = this;
      this.$ajax
        .post(this.url+"/new_registration.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.settime();
          } else {
            _this.$notification.open({
              message: "验证码",
              duration: 4,
              description: response.data.msg,
              onClick: () => {
                console.log("验证码接口");
              },
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    settime() {
      //发送验证码倒计时
      if (this.countdown == 0) {
        this.countdown = 60;
        this.codeText = "发送验证码";

        this.codeBtn = this.phoneCode;
        return;
      } else {
        this.codeText = this.countdown;
        this.codeBtn = true;

        this.countdown--;
      }
      setTimeout(() => {
        this.settime();
      }, 1000);
    },
    checkGrade(rule, value, callback) {
      let structRight = RegExp(
        /^(1[3-9]\d{9})$|^(852\d{8})$|^(853\d{8})$|^(886\d{8})$|^(853\d{7})$|^(886\d{9})$/
      ).test(value);
      if (!structRight) {
        callback(new Error("请填写正确的手机号"));
        this.codeBtn = true;
        this.phoneCode = true;
      } else {
         

        let data = {
          op: "check",
          phone: this.form.phone,
        };
        let _this = this;
        this.$ajax
          .post(this.url+"/new_registration.do", _this.Qs.stringify(data))
          .then(function (response) {
            console.log(response);
            if (response.data.value == "ok") {
              callback();
              _this.codeBtn = false;
              _this.phoneCode = false;
            } else {
              callback(new Error(response.data.msg));
              _this.codeBtn = true;
              _this.phoneCode = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
};
</script>
<style scoped>
/deep/.add .ant-col-17 {
  width: 100%;
}
.codeQ {
  color: #69a4fa;
  cursor: pointer;
  float: right;
}
</style>