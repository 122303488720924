import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios' 
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Qs from 'qs' 

Vue.prototype.$ajax = axios
Vue.prototype.Qs = Qs
//https://eval.t.educg.com
Vue.prototype.url= process.env.VUE_APP_URL
console.log(process.env.VUE_APP_URL)

console.log(process.env)

// 测试提交
// 测试提交




Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

/**
 * Vue.axios.get(api).then((response) => {
  console.log(response.data)
})

this.axios.get(api).then((response) => {
  console.log(response.data)
})

this.$http.get(api).then((response) => {
  console.log(response.data)
})
 * **/
