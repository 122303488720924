import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import loginRegPass from '../views/login-reg-pass.vue'
import loginUrl from '../views/loginUrl.vue'
import regUrl from '../views/regUrl.vue'
import faithUrl from '../views/faith.vue'
import protectUrl from '../views/protect.vue'



import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
Vue.use(VueRouter)
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
      return routerPush.call(this, location).catch(error=> error)
  }
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    redirect: '/index',
    // component: () => import(/* webpackChunkName: "about" */ '../views/start.vue'),
    children: [
      {
        path: '/aboutN',
        name: 'aboutN',
        component: () => import(/* webpackChunkName: "aboutN" */ '../views/aboutN.vue'),

      },
      {
        path: '/index',
        name: 'index',
        component: () => import(/* webpackChunkName: "index" */ '../views/index.vue'),

      },
      {
        path: '/plan',
        name: 'plan',
        component: () => import(/* webpackChunkName: "plan" */ '../views/plan.vue'),

      },
      {
        path: '/view',
        name: 'view',
        component: () => import(/* webpackChunkName: "view" */ '../views/view.vue'),

      },
      {
        path: '/result',
        name: 'result',
        component: () => import(/* webpackChunkName: "result" */ '../views/result.vue'),

      },
      {
        path: '/signUp',
        name: 'signUp',
        component: () => import(/* webpackChunkName: "signUp" */ '../views/signUp.vue'),

      },
      {
        path: '/enroll',
        name: 'enroll',
        component: () => import(/* webpackChunkName: "enroll" */ '../views/enroll.vue'),

      },
      {
        path: '/enrollDetail',
        name: 'enrollDetail',
        component: () => import(/* webpackChunkName: "enrollDetail" */ '../views/enrollDetail.vue'),

      },
      {
        path: '/chDetail',
        name: 'chDetail',
        component: () => import(/* webpackChunkName: "chDetail" */ '../views/chDetail.vue'),

      },
      {
        path: '/sai',
        name: 'sai',
        component: () => import(/* webpackChunkName: "sai" */ '../views/sai.vue'),

      },
      {
        path: '/oldDetail',
        name: 'oldDetail',
        component: () => import(/* webpackChunkName: "oldDetail" */ '../views/oldDetail.vue'),

      },
      {
        path: '/selfDetail',
        name: 'selfDetail',
        component: () => import(/* webpackChunkName: "selfDetail" */ '../views/selfDetail.vue'),

      },
      {
        path: '/sendcert',
        name: 'sendcert',
        component: () => import(/* webpackChunkName: "sendcert" */ '../views/sendcert.vue'),

      },
      {
        path: '/qu',
        name: 'qu',
        component: () => import(/* webpackChunkName: "qu" */ '../views/qu.vue'),

      },
      {
        path: '/core',
        name: 'core',
        component: () => import(/* webpackChunkName: "core" */ '../views/core.vue'),

      },
      {
        path: '/info',
        name: 'info',
        component: () => import(/* webpackChunkName: "core" */ '../views/info.vue'),

      },
      {
        path: '/infoDetail',
        name: 'infoDetail',
        component: () => import(/* webpackChunkName: "core" */ '../views/infoDetail.vue'),

      },
      {
        path: '/dw',
        name: 'dw',
        component: () => import(/* webpackChunkName: "core" */ '../views/dw.vue'),

      },
      {
        path: '/qy',
        name: 'qy',
        component: () => import(/* webpackChunkName: "core" */ '../views/qy.vue'),

      }
      ,
      {
        path: '/sList',
        name: 'sList',
        component: () => import(/* webpackChunkName: "core" */ '../views/sList.vue'),

      },

      
      
      
      
     
      
    
    ]
  },
  // 邀请码登录和注册
  {
    path: '/login-reg-pass',
    name: 'login-reg-pass',
    component: loginRegPass
  },
  {
    path: '/loginUrl',
    name: 'loginUrl',
    component: loginUrl
  },
  {
    path: '/regUrl',
    name: 'regUrl',
    component: regUrl
  },

  {
    path: '/faith',
    name: 'faith',
    component: faithUrl
  },
  {
    path: '/protect',
    name: 'protect',
    component: protectUrl
  },

  
]

const router = new VueRouter({
  routes
})

export default router
