<template>
  <div class="regUrl">
    <p class="ms ms-reg">
      已有账号？
      <span @click="to('./loginUrl')" style="color: #69a4fa; cursor: pointer">前往登录</span>
    </p>
    <!-- <a-steps :current="current" size="small">
      <a-step title="绑定手机" />
      <a-step title="账号信息" />
      <a-step title="设置密码" />
    </a-steps> -->
        <el-steps :active="current"  finish-status="success" align-center>
          <el-step title="绑定手机" ></el-step>
          <el-step title="账号信息" ></el-step>
          <el-step title="设置密码" ></el-step>
        </el-steps>
    <regCom
      class="reg-com"
      @submitFun="submitFun(arguments)"
      v-show="current == 0"
    ></regCom>
    <regDe
      class="reg-com reg-reg"
      @submitFun="submitFun(arguments)"
      v-show="current == 1"
    ></regDe>
    <regPass
      class="reg-com"
      @submitFun="submitFun(arguments)"
      v-show="current == 2"
    ></regPass>
  </div>
</template>
<script>
import regCom from "@/components/regCom.vue";
import regDe from "@/components/regDe.vue";
import regPass from "@/components/regPass.vue";
export default {
  data() {
    return {
      current: 0,
    };
  },
  components: {
    //注册
    regCom,
    regDe,
    regPass,
  },
  methods: {
    submitFun(val) {
      this.current = val[0];
    },
    to(url) {
      this.$router.push({ path: url });
    },
  },
};
</script>
<style scoped>
.regUrl {
  width: 1000px;
  margin: 20px auto;
}
.ms {
  text-align: center;
  margin: 20px 0 50px;
  font-weight: bold;
}
.ms-reg {
  margin: 60px 0;
}
.reg-com {
  padding: 50px 20px 20px !important;
  width: 412px;
  margin: 0 auto !important;
}
/deep/ .ant-steps-item-container {
  text-align: left;
}
.el-step__title{
  font-size:13px !important;
}
</style>